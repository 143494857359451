<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 년도 -->
          <c-datepicker
            type="year"
            default="today"
            label="LBLYEAR"
            name="year"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            type="number"
            suffix="이상"
            label="최종점수"
            name="finalScore"
            v-model="searchParam.finalScore"
          />
        </div>
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="근골격계질환 대상자 목록"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :checkClickFlag="false"
        :columnSetting="false"
        selection="multiple"
        :isExcelDown="false"
        rowKey="heaMuscleWorkerSurveyId"
        :hideBottom="true"
        gridHeight="600px"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'participantStatusPop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        deptCd: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        listUrl: '',
        plantCd: null,
        year: '',
        isSymptom: 'Y',
        finalScore: 0,
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
        ],
        columns: [
          {
            name: 'upDeptName',
            field: 'upDeptName',
            label: '상위부서',
            align: 'center',
            sortable: false,
            style: 'width:130px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'age',
            field: 'age',
            label: '연령',
            align: 'center',
            sortable: false,
            style: 'width:70px',
          },
          {
            name: 'longevityCount',
            field: 'longevityCount',
            label: '근속년수',
            align: 'left',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'part',
            field: 'part',
            label: '부위',
            align: 'center',
            sortable: false,
            style: 'width:350px',
          },
          {
            name: 'painScore',
            field: 'painScore',
            label: '통증점수',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'finalScore',
            field: 'finalScore',
            label: '최종점수',
            align: 'center',
            sortable: false,
            style: 'width:70px',
          },
        ],
        data: [],
      },
      selectData: [],
      editable: true
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.muscleSystem.muscleSurvey.status.url

      this.getList();
    },
    getList() {
      if (!this.searchParam.finalScore) this.searchParam.finalScore = 0;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '대상자를 선택하세요.', // 대상자를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
